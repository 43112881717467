<template>
    <div id="root" class="main-container">
        <template v-if="(userInfo || user) && (!$route.query.system || ($route.query.system && $route.query.system !='false')) && $route.path != '/cloud-login/index'">
            <!-- 左侧菜单区 -->
            <MainMenu class="main-menu-box"/>
            <!-- 右侧视图 -->
            <div class="main-container-content">
                <!-- 上部导航区 -->
                <Navbar/>
                <!-- 子应用渲染区 -->
                <div class="main-container-view">
                    <el-scrollbar class="wl-scroll" style="height: 100%;">
                        <div id="app-viewport" class="app-view-box"></div>
                    </el-scrollbar>
                </div>
            </div>
        </template>
        <template v-else>
            <div id="app-viewport" class="app-view-box" style="width: 100%;height: 100%;"></div>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from "vuex"; // 引入状态共享
    import MainMenu from "@/components/Main-Menu.vue";
    import Navbar from "@/components/Navbar.vue";
    import {apiPost} from "@/utils/axios-utils";
    import {setState} from "./utils/app-store";

    export default {
        name: "root",
        components: {
            MainMenu,
            Navbar
        },
        mounted() {
            let that = this
            if (sessionStorage.getItem('userInfo')) {
                this.$store.dispatch('app/setUserInfo', JSON.parse(sessionStorage.getItem("userInfo")));
                this.user = JSON.parse(sessionStorage.getItem("userInfo"))
                this.getRole(JSON.parse(sessionStorage.getItem("userInfo")).roleId, JSON.parse(sessionStorage.getItem("userInfo")).appId)
            }
            window.onbeforeunload = () => {
                that.$store.getters.userInfo && sessionStorage.setItem("userInfo", JSON.stringify(that.$store.getters.userInfo))
                that.$store.getters.breadcrumb && sessionStorage.setItem("breadcrumb", JSON.stringify(that.$store.getters.breadcrumb))
                that.$store.getters.navbread && sessionStorage.setItem("navbread", JSON.stringify(that.$store.getters.navbread))
                that.$store.getters.activeIndex && sessionStorage.setItem("activeIndex", that.$store.getters.activeIndex)
                that.$store.getters.system && sessionStorage.setItem("system", that.$store.getters.system)
                // that.$store.getters.roleInfo && sessionStorage.setItem("roleInfo", that.$store.getters.roleInfo)
            }
        },
        data() {
            return {
                goSystem:"",
                user:null,
            }
        },
        computed: {
            ...mapGetters(["userInfo"]),
        },
        methods: {
            getRole(roleId, appId) {
                const that = this;
                let formdata = new FormData()
                formdata.append('roleId', roleId)
                formdata.append('appId', appId)
                apiPost(this.$basic + this.$getRoleJurisdiction, formdata).then(res => {
                    if (res.data.code == '0000') {
                        if (res.data.attachObject) {
                            that.$store.getters.roleInfo && sessionStorage.setItem("roleInfo", that.$store.getters.roleInfo)
                        }
                    } else {
                        /* ElMessage({
                            message: '获取角色权限失败',
                            type: 'error',
                        }) */
                    }
                })
            }
        }
    };
</script>

<style lang="scss">
    html,
    body, #app-viewport, #app-viewport > div {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    #main-container {
        height: 100%;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    body::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    /*定义滑块 内阴影+圆角*/
    body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgb(64, 158, 255);
        background-color: #555;
    }

    .el-scrollbar__view {
        height: 100%;
    }

    .main-container {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .main-container-content {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        > .main-container-view {
            padding: 8px;
            width: 100%;
            height: calc(100% - 92px);
            background: $main-base-color;
            box-sizing: border-box;
            > .wl-scroll {
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: 4px;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }

            .app-view-box {
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
            }
        }
    }

    .subapp-loading {
        background: url("~@/assets/images/loading.gif");
    }
    .el-sub-menu__icon-arrow{
        display: none!important;
    }
</style>
