/**
 * @name 无需服务端获取的微应用
 */
// 无需登录的应用
const noAuthApps = [
    // {
    //     module: "cloud-login",
    //     defaultRegister: true,
    //     // entry: window.location.origin + "/cloud-login/",
    //     entry: "http://localhost:8081/cloud-login/",
    //     activeRule: "/cloud-login/",
    //     // useExternals: true,
    //     menus: [
    //         {
    //             id: "1",
    //             title: "index",
    //             icon: "el-icon-monitor",
    //             children: [
    //                 {
    //                     id: "1-1",
    //                     title: "index",
    //                     path: "/index"
    //                 }
    //             ]
    //         }
    //     ]
    // }
]

// 需要登陆身份但是和模块菜单授权无关的子应用
const nextAuthApps = []

export {noAuthApps, nextAuthApps};